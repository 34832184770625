@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Saira+Stencil+One&display=swap");

:root {
  --white: #ffffff;
  --black: #0c0100;
  --gray: #c6c6c6;
  --green: #03ac00;
  --red: #c70000;
  --blue: #0057b8;
  --yellow: #fbc815;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
}

html {
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
button,
input,
select,
textarea {
  font-family: "Raleway", sans-serif;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
